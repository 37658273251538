import React from 'react'

import './footer.css'

function Footer () {

  return (
    <div className="footer-bg">
    <footer className="footer-section">
      <h3>Grab your Wands and join the community!</h3>

      <div className="social-media-container">

        <a target="_blank" href="https://discord.gg/N549CquWKU" className="social-media-icon">
          <span className='icon-discord' />
        </a>
        <a target="_blank" href="https://www.facebook.com/thatswizard" className="social-media-icon">
          <span className='icon-facebook'/>
        </a>
      </div>

      <div className="copyright-container">
        &copy; 2025 Manaworks Games LLC
      </div>

    </footer>
    </div>
  )
}

export default Footer
