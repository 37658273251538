import React from 'react'

import logo from './assets/howtoplay.png'
import nowLive from './assets/now-live.png'
import explosionExample from './assets/explosionExample.png'
import lastingExample from './assets/lastingExample.png'
import turnOrder from './assets/turnOrder.png'
import playerBoard from './assets/playerBoard.png'
import buildingSpellbook from './assets/buildingSpellbook.png'
import focusCounter from './assets/focusCounter.png'
import './learn.css'

function Learn () {

  return (
    <>
      <section className="header-section learn-header">
        <img className="header-logo" src={logo} alt="That's Wizard"/>
        {/*<a href="https://www.kickstarter.com/projects/matthewsorrentino/thats-wizard" target="_blank">
          <img className="now-live-logo" src={nowLive} alt="Now Live!" />
        </a>*/}
      </section>

      <div className='content-bg'>
        <div className="content-border">
        <div className="section-container">

          <section className="learn-sections">

            <h2 className="header-text">How to Play</h2>



            <div className="regular-text">
            That's Wizard! is an easy to learn but difficult to master game that challenges you to predict your opponent's moves and decide whether to cast spells or defend against theirs.
            </div>

            <h2 className="header-text">Casting Spells</h2>

            <div className="regular-text learn-block">

              <figure className="learn-image">
                <img src={explosionExample} alt="Using 4 Power makes Explosion deal 5 Damage" />
                <figcaption className="small-text">Using 4 Power makes Explosion deal 5 Damage</figcaption>
              </figure>
              <ul className="learn-list">
                <li>Each spell card has a blue section that shows the amount of <span className="bold-text">Power</span> you can spend to cast it.</li>
                <li>Most spells give you three different amounts of <span className="bold-text">Power</span> to choose from.</li>
                <li>When reading a spell’s effect, replace the letter <span className="bold-text">P</span> with the amount of Spell Power you used to cast it.</li>
                <li>Quick spells (indicated by the <span className="icon-quick"></span> icon) like <span className="bold-text">Explosion</span> will return to your hand after you have played them.</li>
              </ul>



            </div>

            <div className="regular-text learn-block">

              <figure className="learn-image">
                <img src={lastingExample} alt="Using 2 Power makes Embrace Pain last 3 rounds" />
                <figcaption className="small-text">Using 2 Power makes Embrace Pain last 3 rounds</figcaption>
              </figure>

              <ul className="learn-list">
                <li><span className="bold-text">Lasting</span> spells (indicated by the <span className="icon-lasting"></span> icon) like <span className="bold-text">Embrace Pain</span> will stay in play for multiple rounds.</li>
                <li>When you play a <span className="bold-text">Lasting</span> spell, you will place the appropriate number of <span className="bold-text">Lasting Effect Tokens</span> on it.</li>
                <li>Each subsequent round a token will be removed. When the last token is removed, the <span className="bold-text">Lasting</span> spell will return to your hand.</li>
              </ul>

            </div>

            <h2 className="header-text">Turn Structure</h2>
            <div className="regular-text learn-block">
              <figure className="learn-image">
                <img src={turnOrder} alt="Turn order is resolved from lowest to highest Spell Power" />
                <figcaption className="small-text">Weaker spells are quicker than the more powerful but slower spells!</figcaption>
              </figure>
              <ul className="learn-list">
                <li>Each round players will secretly choose a card and set their <span className="bold-text">Spell Power Dial</span> to an amount of <span className="bold-text">Power</span> allowed by the card.</li>
                <li>Next, players will reveal their chosen card and <span className="bold-text">Spell Power Dials</span> simultaneously.</li>
                <li>Compare the amounts showing on your <span className="bold-text">Spell Power Dials</span> - the lower amount goes first.</li>
              </ul>
            </div>


            <h2 className="header-text">Your Spellbook</h2>
            <div className="regular-text learn-block">
              <figure className="learn-image">
                <img src={buildingSpellbook} alt="" />
                <figcaption className="small-text">To take a level 4 <span className="icon-curse"></span> you must have the level 1,2, and 3 <span className="icon-curse"></span> in your Spellbook. </figcaption>
              </figure>
              <ul className="learn-list">
                <li>Before a match, each player builds a <span className="bold-text">Spellbook</span> of seven spells.</li>
                <li>Each spell has a symbol representing its school of magic and a number representing the spell level.</li>
                <li>In order to take a spell you must have spells from that same school of magic of the lower levels.</li>
              </ul>

            </div>

            <h2 className="header-text">Special Action cards</h2>
            <div className="regular-text learn-block">
              <figure className="learn-image">
                <img src={focusCounter} alt="" />
                <figcaption className="small-text">All players get <span className="bold-text">Focus</span> and <span className="bold-text">Counterspell</span> in addition to their Spellbook</figcaption>
              </figure>
              <ul className="learn-list">
                <li>In addition to your <span className="bold-text">Spellbook</span>, you will also have the following two Special Action Cards.</li>
                <li><span className="bold-text">Focus</span> which restores you to full Power at the end of the round.</li>
                <li><span className="bold-text">Counterspell</span> which stops your opponent's spell and causes them to waste the Power they spent to cast it, but costs some of your own Stamina to do so!</li>
              </ul>
            </div>

            <h2 className="header-text">Defeating your opponent</h2>
            <div className="regular-text learn-block">
              <figure className="learn-image">
                <img src={playerBoard} alt="" />
                <figcaption className="small-text">Track your <span className="bold-text">Stamina</span> and <span className="bold-text">Power</span> on the player board</figcaption>
              </figure>
              <ul className="learn-list">
                <li>Win the duel and earn a <span className="bold-text">Victory Token</span> by reducing your opponent to zero <span className="bold-text">Stamina</span> or forcing them to forfeit with a spell like <span className="bold-text">Polymorph</span> or <span className="bold-text">Disarm.</span></li>
                <li>The first player to win two out of three duels claims victory.</li>
                <li>Pay attention to what spells your opponent has used—timing a <span className="bold-text">Counterspell</span> correctly can turn the tide of battle!</li>
              </ul>

            </div>



            <h2 className="header-text">Rulebook</h2>
              <div className="regular-text">Curious about four-player battles, and more? Download the official <a className="bold-text" href="/That's Wizard Rulebook.pdf" target="_blank" download>That's Wizard! Rulebook</a>  to learn more!</div>
            


          </section>

        </div>
        </div>
      </div>

    </>
  )
}

export default Learn
