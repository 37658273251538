import React, { useContext, useState, useEffect } from 'react'

import logo from './assets/thatswizard.png'
import bestShow from './assets/best-in-show.jpg'
import bestPresentation from './assets/best-presentation.jpg'
import figgyAward from './assets/figgy-award.jpg'
import paxSouth from './assets/pax-south.jpg'
import nowLive from './assets/now-live.png'



import './home.css'

function Home () {

  return (
    <>

      <section className='header-section about-header'>
        <img className="header-logo" src={logo} alt="That's Wizard"/>
        {/*<a href="https://www.kickstarter.com/projects/matthewsorrentino/thats-wizard" target="_blank">
          <img className="now-live-logo" src={nowLive} alt="Now Live!" />
        </a>*/}
      </section>

      <div className='content-bg'>
        <div className="content-border">
        <div className="section-container">

          <section className="home-sections">

            <h2 className="header-text">Get Your Wands Ready!</h2>

            <div className="regular-text">
              That's Wizard! is a <span className="bold-text">two</span> or <span className="bold-text">four</span> player <span className="bold-text">simultaneous-action</span> card game that lets you experience the intensity and excitement of a real wizard duel!
            </div>

            <div className='promo-video-container constrained'>
              <div className="iframe-container">
                <iframe title='Introducting Thats Wizard' width="1920" height="1080" src="https://www.youtube.com/embed/6gcbbTkHZwc?rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>

            <h2 className="header-text">About The Game</h2>

            <div className="awards-container constrained">

              <div className="award-container">
                <div className="award-image">
                  <img src={paxSouth}/>
                </div>
              </div>

              <div className="award-container">
                <div className="award-image">
                  <img src={bestShow}/>
                </div>
              </div>

              <div className="award-container">
                <div className="award-image">
                  <img src={bestPresentation}/>
                </div>
              </div>

              <div className="award-container">
                <div className="award-image">
                  <img src={figgyAward}/>
                </div>
              </div>

            </div>

            <div className="regular-text">
            That's Wizard! has been developed over many years with the support of numerous fans, playtesters,
              and world-class artists. It has been extensively tested and balanced towards the goal
              of making each wizard duel a fun and intense experience!
            </div>

          </section>

        </div>
        </div>
      </div>


    </>
  )
}

export default Home
